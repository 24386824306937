
/* =======================================
INner Pages
=========================================*/

#page-banner{
    position: relative;
    padding: 60px 0px 80px;
}
.page-banner-area{
    background:url("../images/banner/1.jpg")no-repeat 50% 50%;
    background-size: cover;
}

