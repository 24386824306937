
/*--------------------------
  SERVICE AREA SECTION
----------------------*/
.page-banner-area.page-service{
    background: url("../images/bg/bg-2.jpg") no-repeat;
    background-size: cover;
}
#service-head {
    padding-bottom: 100px;
    padding-top: 90px;
}
#service-head .section-title{
    color: $light;
}
.text-white p {
    color: $light;
}

#service {
    margin-top: -100px;
    padding-bottom:70px;
}

.service-box .service-img-icon {
    width: 100px;
    height: 100px;
    text-align: center;
    background: $light;
    padding-top: 20px;
    margin: 0 auto;
    border-radius: 100%;
    margin-bottom: 20px;
    background:$primary-color;
    border: 1px solid rgba(255,255,255,.2);
    color: $light;
}

.service-box {
    margin-bottom: 40px;
    background: $light;
    text-align: center;
    padding: 45px 25px 30px 25px;
    border: 1px solid transparent;
    -webkit-transition: all .3s ease 0s;
    -o-transition: all .3s ease 0s;
    transition: all .3s ease 0s;
    border-radius: 5px;
    -webkit-box-shadow: 0px 2px 6px rgba(0,0,0,0.1);
    box-shadow: 0px 2px 6px rgba(0,0,0,0.1);
}


.service-inner {
    margin-top: 30px;
}

.service-inner h4 {
    margin-bottom: 20px;
}

.service-inner span {
    color:$primary-color;
}


/* ================================
SERVICE PAGE
==============================*/

.bg-gray{
    background: $secondary-color;
}

.service-block-2{
    position: relative;
}
.service-block-2 h4{
    margin-bottom: 15px;
    line-height: 26px;
}

.service-block-2 p{
    font-size: 16px;
}

.service-block-2 span{
    position: absolute;
    content: "";
    font-size: 70px;
    top: 0px;
    left: 0px;
    font-weight: 800;
    opacity: .05;
    margin-top: -15px;
}

.service-style-two{
    background: url("../images/bg/bg-2.jpg") no-repeat;
    background-size: cover;
    position: relative;
}

.service-style-two:before{
    position: absolute;
    content:"";
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    background:rgba(0,0,0,0.6);
}


.heading{
    margin-bottom: 70px;
}