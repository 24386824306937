
/*-------------------- ------------
 INTRO SECTION
-----------------------------*/

#intro {
    padding-bottom: 0px;
}

.intro-img {
    margin-top: 45px;
}

#intro .section-heading p {
    color:$black;
    font-size: 30px;
    line-height: 40px;
}

.intro-box {
    margin-top: 30px;
    position: relative;
    margin-bottom: 20px;
}

.intro-box h4:before {
    position: absolute;
    left: 0px;
    top: 17px;
    content: "";
    bottom: 0px;
    background:$black;
    width: 50px;
    height: 2px;
}

.intro-box h4 {
    text-transform: uppercase;
    margin-bottom: 15px;
    position: relative;
    padding-left: 60px;
}

.intro-box span {
    font-size: 28px;
    color: $primary-color;
}


.intro-cta{
    margin-top: 40px;
}



