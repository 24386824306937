
/*--------------------------
 CONTACT SECTION AREA
----------------------*/
#contact{
    padding-bottom: 100px;
}
.text-color {
    color: $primary-color;
}

 input[type=text],input[type=email] {
    height: 50px;
}

 .form-control {
    border: 1px solid $border-color;
    padding-left: 20px;
}

.form-control:hover,
.form-control:focus {
    -webkit-box-shadow: 0px 5px 35px 0px rgba(148, 146, 245, 0.15);
    box-shadow: 0px 5px 35px 0px rgba(148, 146, 245, 0.15);
}

.input:focus {
    outline: none;
}


.form-control::-webkit-input-placeholder {
    color:$black-lighter;
    opacity: .9;
    font-size: 13px;
    padding-left: 10px;
}

.form-control::-moz-placeholder {
    color:$black-lighter;
    opacity: .9;
    font-size: 13px;
    padding-left: 10px;
}

.form-control:-ms-input-placeholder {
    color:$black-lighter;
    opacity: .9;
    font-size: 13px;
    padding-left: 10px;
}

.form-control:-moz-placeholder {
    color:$black-lighter;
    opacity: .9;
    font-size: 13px;
    padding-left: 10px;
}




/* --------------
    MAP
-----------------*/

#map{
    height: 400px;
    width: 100%;
    position: relative;
}


#map:after {
    position: absolute;
    content: "";
    left: 0px;
    background: rgba(99, 92, 219,.4);
    top: 0px;
    width: 100%;
    height: 100%;
}
