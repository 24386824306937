/*
* ----------------------------------------------------------------------------------------
Author       : Themefisher
Template Name: Promodise
Version      : 1.0
* ----------------------------------------------------------------------------------------
*/

/*
* ----------------------------------------------------------------------------------------
* 01.GLOBAL CSS STYLE
* 02.PRELOADER & BOUNCE CCS STYLE
* 03.HOMEPAGE CSS STYLE
    -LOGO CSS
    -MENU CSS
* 04.ABOUT CSS STYLE
* 05.SERVICE CSS STYLE
* 06.WORK CSS STYLE
* 07.AVAILABLE CSS STYLE
* 08.CONTACT CSS STYLE
* 09.FOOTER CSS STYLE
* ----------------------------------------------------------------------------------------
*/

/*
* ----------------------------------------------------------------------------------------
* 01.GLOBAL STYLE
* ----------------------------------------------------------------------------------------
*/// Fonts 
@import url('https://fonts.googleapis.com/css?family=Poppins:400,600,700|Rubik:400,600,700');


body {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    font-family: $secondary-font;
    color: #788487;
    position: relative;
}

p {
    line-height: 28px;
    font-size: 15px;
}

html {
    overflow-x: hidden !important;
    width: 100%;
    height: 100%;
    position: relative;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

body {
    border: 0;
    margin: 0;
    padding: 0;
}

h1,.h1,
h2,.h2,
h3,.h3,
h4,.h4,
h5,.h5,
h6 ,.h6{
    font-family: $primary-font;
    font-weight: 600;
    margin-bottom: 0px;
    color: $black-light;
}

a {
    text-decoration: none;
    -webkit-transition: all 0.4s;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    color:$black;
}

img {
    max-width: 100%;
}

a:hover {
    color: #635CDB;
    text-decoration: none;
}

a:focus {
    outline: none;
    text-decoration: none;
}

ul,
li {
    margin: 0;
    padding: 0;
    list-style: none;
}




h1 {
    font-size: 48px;
}

h2 {
    font-size: 36px;
}

h3 {
    font-size: 28px;
    line-height: 38px;
}

h4 {
    font-size: 22px;
    line-height: 32px;
}

h5 {
    font-size: 16px;
    line-height: 24px;
}

h6 {
    font-size: 14px;
    line-height: 26px;
}


.f-color {
    color: #635CDB;
}

h5.subtitle {
    font-size: 16px;
    font-weight: 400;
}